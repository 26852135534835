<div class="container-fluid">

  <div class="text-center py-1 bg-primary shadow-none"
       style="background-image: url(./assets/img/virions.png);
       background-position: center;
       background-size: 100%;
       background-repeat: repeat;
       height: 200px;">
    <div class="container-fluid" style="padding-top: 1vw; padding-bottom: 5vw;">
      <div class="row mt-1 mb-5 ml-1 mr-1">
            <div class="col-lg-2 col-md-3  col-sm-4  bg-light" style="border-radius: 2vw">
              <img class="img-fluid mt-2" src="./assets/img/logo_white_2.png">
              <p style="font-size: 0.75rem;">Immune Receptor Genotypes and Haplotypes</p>
            </div>
      </div>
    </div>
  </div>

  <div class="pl-1 pr-5 pt-5" style="background-color: #f5f5f5; color: #000000;">
    <div class="container">
      <div class="row">
        <div class = "col-md-6">
          <div class="row">
            <div class="col-md-6">
              <h1 class="mat-h1">Latest News (<a href="{{wpConfig.wp_news_url}}/index.php/vdjbase_news">more</a>)</h1>
            </div>
          </div>

          <div  class="spinner-border" role="status" *ngIf="loadingNews">
            <span class="sr-only">Loading...</span>
          </div>

          <div class="alert alert-danger" *ngIf="errorNews">
            <h4>Error loading news</h4>
          </div>

          <div>
            <p *ngIf="loadedNewsPosts.length < 1 && !loadingNews && !errorNews">No posts available!</p>
            <div *ngIf="loadedNewsPosts.length >= 1 && !loadingNews">
              <ul class="list-unstyled">
                <li *ngFor="let post of loadedNewsPosts">
                  <a href="{{ post[1] }}">{{ post[0] }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class = "col-md-6">
          <div class="row">
            <div class="col-md-6">
              <h1 class="mat-h1">Help topics (<a href="{{wpConfig.wp_help_url}}/index.php/vdjbase_help">more</a>)</h1>
            </div>
          </div>

          <div  class="spinner-border" role="status" *ngIf="loadingHelp">
            <span class="sr-only">Loading...</span>
          </div>

          <div class="alert alert-danger" *ngIf="errorHelp">
            <h4>Error loading help</h4>
          </div>

          <div>
            <p *ngIf="loadedHelpPosts.length < 1 && !loadingHelp && !errorHelp">No posts available!</p>
            <div *ngIf="loadedHelpPosts.length >= 1 && !loadingHelp">
              <ul class="list-unstyled">
                <li *ngFor="let post of loadedHelpPosts">
                  <a href="{{ post[1] }}">{{ post[0] }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <h1 class="mat-h1">About VDJbase</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" style="">
          <p>
            VDJbase is a database of adaptive immune receptor genes, genotypes and haplotypes. Our core collection is inferred from immune receptor
            repertoire sequences, as recently described in <a href="https://doi.org/10.1093/nar/gkz872">Nucleic Acids Research</a>. We are also beginning to add genomically derived material.
          </p>
          <p>
            Detailed knowledge of these genes, in humans and key research species, is vital to the understanding of the immune response. Current
            reference sets, widely used in immune repertoire research, are often incomplete. Problems in the
            <a href="https://onlinelibrary.wiley.com/doi/abs/10.1038/sj.icb.7100144">human</a> and
            <a href="https://onlinelibrary.wiley.com/doi/abs/10.1111/imcb.12288">mouse</a> sets are long-standing.
            VDJbase allows researchers to consult inferences from many hundreds of samples, allowing a population-level
            understanding of genes and alleles to be developed, and hitherto unidentified alleles to be described.
          </p>
          <p>
            VDJbase provides customisable reports, which allow users to study gene and allele usage in various ways. Reports, and the data underlying them,
            are provided under open-source licence. If you use VDJbase in your research, please
            <a href="https://academic.oup.com/nar/article/48/D1/D1051/5585549#">cite our paper</a>.
          </p>
          <p>
            From here you can:
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" style="">
          <ul>
            <li><a routerLink="./datarep">Explore the data</a> available in VDJbase today</li>
            <li>Read our <a routerLink="./user-guide">user guide</a> or find more information on <a href="./assets/doc/report_documentation.pdf">reports</a></li>
            <br>
            <li>Browse the <a routerLink="./samplerep/null">adaptive immune receptor repertore (AIRR) datasets</a></li>
            <li>Browse <a routerLink="./generep">alleles</a> identified from the AIRR datasets</li>
            <br>
            <li>Browse the <a routerLink="./genesample/null">genomically-derived datasets</a></li>
            <li>Browse <a routerLink="./genetable">alleles</a> identified from the genomically-derived datasets</li>
            <li>View <a routerLink="./genebrowser/Human/Human_IGH">alignments</a> in a genome browser.</li>
          </ul>

          <p>All options are also available from the menu at the top of each page.</p>

          <p>
            VDJbase is jointly developed and maintained by members of the <a href="http://yaarilab.com/">Yaari Lab</a> at the Faculty of Engineering at Bar Ilan University, Israel,
            and the <a href="http://shepherd-group.ismb.lon.ac.uk/research.html">Shepherd Group</a> at the Institute of Structural and Molecular Biology, Birkbeck College, London.
            It is hosted at Birkbeck. For further information, please contact <a href="http://yaarilab.com/contact">Gur Yaari</a> or
            <a href="mailto:william@lees.org.uk">William Lees</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>


