<div class="row">
  <div  class="spinner-border" role="status" *ngIf="dataSource.loading$ | async">
    <span class="sr-only">Loading...</span>
  </div>

  <div class="alert alert-danger" *ngIf="dataSource.error$ | async">
    <div class="row">
      <h4>Error loading table</h4>
      <p>{{dataSource.error$ | async}}</p>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-auto" style="align-items: center; justify-content: flex-start; display: flex;">
    <span class="header-text">Quick search:&nbsp;</span>
    <input class="searchBox" #searchBox>
  </div>

  <div class="col-auto" style="align-items: center; justify-content: flex-start; display: flex; padding-left: 0px !important;">
    <button style="border-width: 0px; background-color: transparent;" (click)="clearSelection()">
      <mat-icon class="primary-icon"  style="">delete_sweep</mat-icon>
      <span class="header-text">Clear filters</span>
    </button>
  </div>

  <div class="col-auto" style="align-items: center; justify-content: flex-start; display: flex; padding-left: 0px !important;">
    <button digby-table-column-sorter
       [(columns)]="displayedColumns"
       [columnInfo]="allColumns"
       [saveName]="'gen-sample-table'"
       (columnsChange)="updateColumnData($event)" id="colbutton">
       <mat-icon class="primary-icon"  style="padding-left: 0px !important;">view_column</mat-icon>
    </button>
      <label class="form-check-label" for="colbutton">Arrange Columns</label>
  </div>

  <div class="col-auto" style="align-items: center; justify-content: flex-start; display: flex; padding-left: 0px !important;">
    <button style="border-width: 0px; background-color: transparent;" [matMenuTriggerFor]="downloadMenu" id="downloadbutton">
      <mat-icon class="primary-icon"  style="">cloud_download</mat-icon>
    </button>
    <label class="form-check-label" for="downloadbutton">Download</label>
  </div>

  <div class="col-auto" style="align-items: center; justify-content: flex-start; display: flex;" *ngIf="selectedSequenceNames.length">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="chk-selected-items" style="margin-top: .1rem;"
        [(ngModel)]="isSelectedSamplesChecked"
        (change)="(onSelectedSamplesChange())"
      >
      <label class="form-check-label" for="chk-selected-items">
        Only records with selected genes
      </label>
    </div>
  </div>

  <div class="col-auto ml-auto">
    <mat-paginator [length]="dataSource?.totalItems" [pageSize]="25" [pageSizeOptions]="[25, 50, 100, 250]"></mat-paginator>
  </div>
</div>

<div class="row">
  <div class="col">
    <mat-table [dataSource]="dataSource">


      <ng-container *ngFor="let col of allColumns">
        <ng-container *ngIf="(col.type === 'string')">
            <ng-container matColumnDef={{col.id}}>
              <mat-header-cell class="{{col.size}}" *matHeaderCellDef mwlResizable [enableGhostResize]="true" (resizeEnd)="onResizeEnd($event, col.id)" [resizeEdges]="{bottom: false, right: true, top: false, left: true}">
                {{col.name}}
                <div *ngIf="col.id === 'identifier'" >
                  <app-filter #filterComponent="menuInOtherComponent" [columnName]="col.id" [filterMode]="filterModeEnum.TEXT_MODE" [setFilter$] = "setFilter$" [clear$] = "clear$" [choices$]="dataSource.choices$" (predicateEmitter)="applyFilter($event)"></app-filter>
                </div>
                <div *ngIf="col.id !== 'identifier'" >
                  <app-filter #filterComponent="menuInOtherComponent" [columnName]="col.id" [filterMode]="filterModeEnum.TEXT_MODE" [clear$] = "clear$" [choices$]="dataSource.choices$" (predicateEmitter)="applyFilter($event)"></app-filter>
                </div>
              </mat-header-cell>
                <div *ngIf="col.id === 'annotation_path'" >
                  <mat-cell *matCellDef="let item" class="small-col">
                    <ng-container *ngIf="item[col.id].length > 0">
                        <button class="btn btn-sm btn-solid-icon" style=""[matMenuTriggerFor]="genoMenu" [matMenuTriggerData]="{sample: item}">
                          <i class="fa fa-folder fa-5" style="line-height: inherit;"></i>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="item[col.id].length === 0">
                      NA
                    </ng-container>
                  </mat-cell>
                </div>
                <div *ngIf="col.id === 'identifier'">
                  <mat-cell class="{{col.size}}" *matCellDef="let item" (click)="showInfo(item)">
                    {{item[col.id]}}
                  </mat-cell>
                </div>
                <div *ngIf="col.id !== 'annotation_path' && col.id !== 'identifier'">
                  <mat-cell class="{{col.size}}" *matCellDef="let item">
                    <ng-container *ngIf="item[col.id] && item[col.id].startsWith('http')">
                      <a href={{item[col.id]}}><i class="fa fa-external-link" style="line-height: inherit;"></i></a>
                    </ng-container>
                    <ng-container *ngIf="!(item[col.id] && item[col.id].startsWith('http'))">
                      {{item[col.id]}}
                    </ng-container>
                  </mat-cell>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="(col.type === 'integer' || col.type === 'number')">
            <ng-container matColumnDef={{col.id}}>
              <mat-header-cell class="{{col.size}}" *matHeaderCellDef mwlResizable [enableGhostResize]="true" (resizeEnd)="onResizeEnd($event, col.id)" [resizeEdges]="{bottom: false, right: true, top: false, left: true}">
                {{col.name}}
                <app-filter #filterComponent="menuInOtherComponent" [columnName]="col.id" [filterMode]="filterModeEnum.NUMBER_MODE" [setFilter$] = "setFilter$" [clear$] = "clear$" [choices$]="dataSource.choices$" (predicateEmitter)="applyFilter($event)"></app-filter>
              </mat-header-cell>
              <mat-cell class="{{col.size}}" *matCellDef="let item">
                  {{item[col.id]}}
              </mat-cell>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="(col.type === 'boolean')">
            <ng-container matColumnDef={{col.id}}>
              <mat-header-cell class="{{col.size}}" *matHeaderCellDef mwlResizable [enableGhostResize]="true" (resizeEnd)="onResizeEnd($event, col.id)" [resizeEdges]="{bottom: false, right: true, top: false, left: true}">
                {{col.name}}
                <app-filter #filterComponent="menuInOtherComponent" [columnName]="col.id" [filterMode]="filterModeEnum.BOOL_MODE" [setFilter$] = "setFilter$" [clear$] = "clear$" [choices$]="dataSource.choices$" (predicateEmitter)="applyFilter($event)"></app-filter>
              </mat-header-cell>
              <mat-cell class="{{col.size}}" *matCellDef="let item">
                <i *ngIf="item[col.id]" class="fa fa-check" style="line-height: inherit;"></i>
              </mat-cell>
            </ng-container>
        </ng-container>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true "></mat-header-row>

      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    </mat-table>
    </div>
</div>


<mat-menu #genoMenu="matMenu" xPosition="before" class="menu-overflow-hidden">
  <div  (click) = "$event.stopPropagation()">
    <ng-template matMenuContent let-sample="sample">
      <div class="container">
        <div class="row ml-1 mr-2">
          <b>{{sample.name}}</b>
        </div>
        <div class="row ml-1 mr-2 mb-1">
          Genotype Analysis
          <span>&nbsp;&nbsp;</span>
          <button class="btn btn-sm btn-solid-icon addr" (click)="sendReportRequest('rep_single_genotype', 'pdf', sample)">
            <i class="fa fa-file-pdf-o" style="line-height: inherit;" ></i>
          </button>
          <span>&nbsp;&nbsp;</span>
          <button class="btn btn-sm btn-solid-icon addr" (click)="sendReportRequest('rep_single_genotype', 'html', sample)">
            <i class="fa fa-desktop" style="line-height: inherit;"></i>
          </button>
        </div>
        <ng-container *ngIf="sample.annotation_method=='IGenotyper'">
          <div class="row ml-1 mr-2 mb-1">
            IGenotyper
          </div>
          <div class="row ml-4 mr-2 mb-0 align-middle">
            Annotation<span>&nbsp;&nbsp;</span><a href="{{sample.annotation_path}}" target="_blank"><i class="fa fa-save" style="line-height: inherit;"></i></a>
          </div>
          <div class="row ml-4 mr-2 mb-1">
            Documentation<span>&nbsp;&nbsp;</span>
            <a href="{{sample.annotation_reference}}" target="_blank"><i class="fa fa-desktop" style="line-height: inherit;"></i></a>
          </div>
        </ng-container>
        <ng-container *ngIf="sample.annotation_method=='VDJbase'">
          <div class="row ml-2 mr-2 mb-1">
            VDJbase
          </div>
          <div class="row ml-4 mr-2 mb-0 align-middle">
            Annotation<span>&nbsp;&nbsp;</span><a href="{{sample.annotation_path}}" target="_blank"><i class="fa fa-save" style="line-height: inherit;"></i></a>
          </div>
          <div class="row ml-4 mr-2 mb-1">
            Documentation<span>&nbsp;&nbsp;</span>
            <a href="{{sample.annotation_reference}}" target="_blank"><i class="fa fa-desktop" style="line-height: inherit;"></i></a>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </div>
</mat-menu>

<mat-menu #downloadMenu="matMenu" xPosition="before" class="menu-overflow-hidden">
  <ng-template matMenuContent>
    <div class="container">
      <div class="row ml-1 mr-2">
        <b>Download data for selected samples</b>
      </div>
      <div class="row ml-4 mr-2 mb-0 align-middle">
        Sample info (csv)
          <span>&nbsp;&nbsp;</span>
          <button class="btn btn-sm btn-solid-icon addr" (click)="sendReportRequest('download_gen_data', 'csv', {type:'Sample info (CSV)'})">
            <i class="fa fa-file-pdf-o" style="line-height: inherit;" ></i> </button>
      </div>
      <div class="row ml-4 mr-2 mb-0 align-middle">
        Sample data (zip)
          <span>&nbsp;&nbsp;</span>
          <button class="btn btn-sm btn-solid-icon addr" (click)="sendReportRequest('download_gen_data', 'zip', {type:'Sample files (ZIP)'})">
            <i class="fa fa-file-zip-o" style="line-height: inherit;" ></i> </button>
      </div>
    </div>
  </ng-template>
</mat-menu>

